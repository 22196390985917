<template>
  <admin>
    <page-header :back="back_url">
      <template v-slot:title>
        <span v-text="trans('Edit Sales Consultant')"></span>
      </template>
    </page-header>

    <div class="row">
      <div class="col-lg-9 col-12">
        <Details action="edit" v-if="show" :details="details" />
      </div>
    </div>
  </admin>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Details from "./partials/forms/Details.vue";

export default {
  components: {
    Details,
  },
  data() {
    return {
      show: false,
      back_url: {
        to: {
          name: "dealers-account-maintenance.sales-consultants.index",
        },
        text: trans("Sales Consultants"),
      },
    };
  },
  computed: {
    ...mapGetters({
      details: "dealerSalesConsultant/GET_SALES_CONSULTANT",
    }),
  },
  methods: {
    ...mapActions({
      getSalesConsultant: "dealerSalesConsultant/getSalesConsultant",
    }),
    is_admin() {
      const user = JSON.parse(localStorage.user);

      if (user.is_admin) {
        this.back_url.to = {
          name: "dealers-account-maintenance.dealers.salesconsultants",
          params: { id: this.details.dealer.id },
        };
      }
    },
  },
  async mounted() {
    await this.getSalesConsultant(this.$route.params.id);
    this.is_admin();
    this.show = true;
  },
};
</script>

<style></style>
